import React from 'react'
import { Tweet } from "react-twitter-widgets";
import { Box } from "@chakra-ui/core";


const DarkTheme = ({ tweetId }) => (
    <Box width={{
        base: "400px",
        sm: "400px",
        md: "400px",
        lg: "400px",
        xl: "500px"
    }}
    >
        <Tweet
            tweetId={tweetId}
            options={{
                theme: "dark",
                width: "320px",
                conversation: "none",
                cards: "hidden"
            }}
        />
    </Box>
);

export default DarkTheme;