import React from 'react'
import SubsribeForm from '../components/subsribe-form'
import { Flex, Text } from "@chakra-ui/core";

const EndSubcribeSection = (props) => {
    return (
        <Flex
            id={props.id}
            direction="column"
            align="center"
            justify={["left", "left", "left", "center"]}
            height={["20em", "20em", "30em", "30em"]}
        >
            <Text fontSize="3xl" fontWeight="bold" textAlign={["left", "left", "left", "center"]}>
                Interested?
                </Text>

            <Text textAlign={["left", "left", "left", "center"]}>
                Subscribe to our newsletter we notify you when done.
                </Text>

            <SubsribeForm />

        </Flex>
    );
}

export default EndSubcribeSection;