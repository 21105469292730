import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DemoVideo from '../components/demo-video'
import UseCasesTweet from "../components/use-cases"
import EndSubcribeSection from "../components/end-subsribe-form"
import HeroSection from "../components/hero-section"
import FollowUsSection from "../components/follow-us"

import { Flex, Box } from "@chakra-ui/core"

import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);



const IndexPage = () => (
  <Layout>
    <SEO title="Home" />


    {/* 
    <div style={{
      maxWidth: 1200,
      marginBottom: `1.45rem`,
    }}>
*/}

    <Flex
      as="flex"
      overflowX="hidden"
      direction="column"
      margin="10 auto"
      width="100%"
    >

      <HeroSection id="main-section" />

      <DemoVideo id="demo-video" />

      <UseCasesTweet id="use-cases-section" />

      <EndSubcribeSection id="end-subsribe-form-section" />

      <FollowUsSection id="follow-us-section" />
      {/*</div>*/}
    </Flex>
  </Layout>
)

export default IndexPage
