import React, { useState } from 'react';
import { Flex, Input, Button, Text } from "@chakra-ui/core";
import { API, graphqlOperation } from 'aws-amplify';
import isEmail from 'validator/lib/isEmail';
import { listSubsriberEmails } from '../graphql/queries';
import { createSubsriberEmail } from '../graphql/mutations';

const SubsribeForm = () => {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage ] = useState(null)
    const [ successMessage, setSuccessMessage ] = useState(null)
    
    
    const handleEmail = event => {
        setErrorMessage(null)
        setSuccessMessage(null)
        setEmail(event.target.value)
    }

    async function processEmail() {
        setIsLoading(true)
        console.log(email)

        /*eslint-disable eqeqeq*/
        if(email === "") {
            setIsLoading(false)
            setErrorMessage("Please enter your email")
        }
        else{
        if (isEmail(email)) {
            try {
                const emailData = await API.graphql(graphqlOperation(listSubsriberEmails, { filter: { emailAddress: { contains: email } } }))

                console.log(emailData.data.listSubsriberEmails)
                
                if (emailData.data.listSubsriberEmails.items.length == 0) {

                    createSubsriber();
                } else {
                    
                    setIsLoading(false)
                    
                    setErrorMessage("You already subsribe. Just wait for our update")
                }
                


            } catch (error) {
                setIsLoading(false)
                setErrorMessage("Some unknown error. Try again later")
                
            }
        }else{
            setErrorMessage("Please enter a valid email")
            setIsLoading(false)
        }
    }
    }

    async function createSubsriber() {
        try {
            await API.graphql(graphqlOperation(createSubsriberEmail, {input:{emailAddress: email}}))
            setIsLoading(false)
            setEmail("")
            setSuccessMessage("Nice! We will send you an email when SarahAssistant is ready")
        } catch (error) {
            
            setErrorMessage("Some unknown error. Try again later")
            setIsLoading(false)
        }
    }

    return (
        <Flex
            direction='column'
            align='center'
            justify={['left', 'left', 'left', 'center']}
        >
            <Flex
                align='center'
                justify='center'
                maxWidth='900px'
                m="0 auto"
                marginTop="20px">

                <Input
                    isRequired={true}
                    value={email}
                    onChange={handleEmail}
                    placeholder="Your email address"
                    size="lg"
                    marginRight="10px"
                />

                <Button
                    isLoading={isLoading}
                    colorScheme="teal"
                    size="lg"
                    onClick={() => processEmail()}
                >
                    Notify me!
                </Button>
            </Flex>

            {errorMessage && 
                <Text 
                    fontSize="sm"
                    marginTop="3px"
                    color="red.500"
                    textAlign="left"
                    >
                    {errorMessage}
                </Text>
            }

            {successMessage && 
                <Text 
                    fontSize="sm"
                    marginTop="3px"
                    color="green.500"
                    textAlign="left"
                    >
                    {successMessage}
                </Text>
            }


            

            <Text
                fontSize="sm"
                marginTop='5px'
                textAlign="center"
            >
                No spam. We just update you when Sarah Assistant is ready.
            </Text>

        </Flex>
    )


};

export default SubsribeForm;