/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubsriberEmail = /* GraphQL */ `
  mutation CreateSubsriberEmail(
    $input: CreateSubsriberEmailInput!
    $condition: ModelSubsriberEmailConditionInput
  ) {
    createSubsriberEmail(input: $input, condition: $condition) {
      id
      emailAddress
      createdAt
      updatedAt
    }
  }
`;
export const updateSubsriberEmail = /* GraphQL */ `
  mutation UpdateSubsriberEmail(
    $input: UpdateSubsriberEmailInput!
    $condition: ModelSubsriberEmailConditionInput
  ) {
    updateSubsriberEmail(input: $input, condition: $condition) {
      id
      emailAddress
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubsriberEmail = /* GraphQL */ `
  mutation DeleteSubsriberEmail(
    $input: DeleteSubsriberEmailInput!
    $condition: ModelSubsriberEmailConditionInput
  ) {
    deleteSubsriberEmail(input: $input, condition: $condition) {
      id
      emailAddress
      createdAt
      updatedAt
    }
  }
`;
