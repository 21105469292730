/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSubsriberEmail = /* GraphQL */ `
  query GetSubsriberEmail($id: ID!) {
    getSubsriberEmail(id: $id) {
      id
      emailAddress
      createdAt
      updatedAt
    }
  }
`;
export const listSubsriberEmails = /* GraphQL */ `
  query ListSubsriberEmails(
    $filter: ModelSubsriberEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubsriberEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailAddress
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
