import React from 'react'
import {
    Flex,
    Text,
    Box
} from "@chakra-ui/core";

//import { FiTwitter, FiYoutube } from "react-icons/fi"

import { SiTwitter, SiYoutube } from "react-icons/si"

const FollowUsSection = (props) => {
    return (

        <Flex
            id={props.id}
            direction="column"
            align="center"
            justify="center"
            height="20%"
        >

            <Text 
                fontSize="3xl"
                fontWeight="bold"
            >
                Follow Sarah Today
            </Text>

            <Flex
                direction="row"
                align="center"
                justify="center"
            >

                <Box as={SiTwitter} 
                    size="64px" 
                    color="blue.300"
                    margin="20px" 
                    onClick={() => window.open("https://twitter.com/sarah_assistant", '_blank') }
                />

                <Box 
                    as={SiYoutube} 
                    size="64px" 
                    color="red.500"
                    margin="20px"
                    onClick={() => window.open('https://www.youtube.com/channel/UC0F-E3rvSw2MUI_mf66nbrQ?sub_confirmation=1' ,'_blank')}
                 />

            </Flex>
        </Flex>
    );
}

export default FollowUsSection;