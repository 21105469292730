import React from 'react'
import { Text, Flex, Box } from "@chakra-ui/core";
import DarkTheme from '../components/tweet';

const UseCasesTweet = (props) => {

    var tweetArray = ["1310149900793868288", "1310149902463242243", "1310149904333828102", "1310149906078732288", "1310149909585244160"]
    return (
        <Box
            as="flex"
            id={props.id}
            direction="column"
            align="center"
            justify="center"
            wrap="wrap"
        >
            <Text
                fontSize="3xl"
                fontWeight="bold"
                textAlign={["left", "left", "left", "center"]}
            >
                Sarah can do everything. Here is some of the use case
            </Text>

            <Text textAlign={["left", "left", "left", "center"]}>
                If you have any intersting use cases, tweet with #SarahUseCase, your tweet may appear here!
            </Text>

            <Flex
                direction={{
                    base: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                }}

                justify="center"
                width="100%"
                minWidth="600px"
                wrap="wrap"
            >

                {tweetArray.map((tweetId, key) => (
                    <DarkTheme
                        key={key}
                        tweetId={tweetId}
                    />
                ))}


            </Flex>
        </Box>
    );
}

export default UseCasesTweet;