import React from 'react'
import { Flex, Text } from "@chakra-ui/core";
import SubsribeForm from './subsribe-form'
import { IconButton } from "@chakra-ui/core";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { navigate } from '@reach/router';


const HeroSection = (props) => {

    const handleButton = event => {
        navigate("/#demo-video")
        console.log("button clicked")
    }

    return (
        <Flex
            id={props.id}
            direction="column"
            align="center"
            justify={{
                base: "start",
                sm: "start",
                md: "start",
                lg: "center",
                xl: "center"
            }}
            height={["35em", "35em", "40em", "50em"]}
        >
            <Text
                fontSize={["3xl", "3xl", "3xl", "6xl"]}
                fontWeight="bold"
                textAlign="center"
                lineHeight="120%"
            >
                Sarah <br />
                The only asistant <br />
                you truly need
                </Text>

            <Text
                fontSize={["xl", "xl", "xl", "2xl"]}
                textAlign="center"
                lineHeight="150%"
            >
                Sarah is your AI Assistant that manage your day-to-day life. <br />
                    Even without using your hand at all. <br />
                    It is your secretary that can standby for you 24/7 365 days.
                </Text>


            <SubsribeForm />

            <IconButton
                size="lg"
                marginTop="3em"
                aria-label="More Details"
                icon={<ArrowDownIcon />}
                marginBottom="10px"
                onClick={() => handleButton()}
            />

        </Flex>
    );
}

export default HeroSection;