import React from 'react';
import { Text, Flex, Box, AspectRatio } from "@chakra-ui/core";


const DemoVideo = (props) => {

    return (
        <Flex
            id={props.id}
            margin="0 auto"
            direction="column"
            align="center"
            justify="center"
            height="auto"
        >

            <Flex
                direction="column"
                justify={["left", "left", "center", "center"]}
            >
                <Text
                    fontSize="3xl"
                    fontWeight="bold"
                    textAlign={["left", "left", "left", "center"]}
                >
                    Time for demo
                </Text>

                <Text marginBottom="5vh"
                    textAlign={["left", "left", "left", "center"]}>
                    You are very busy person. Go do something productive. For recording, just "talk" to Sarah,
                    it help you keep track everything.
                </Text>

            </Flex>


            <Flex
                margin="0 auto"

                justify="center"
                direction={{
                    base: "column",
                    sm: "column",
                    md: "column",
                    lg: "row",
                    xl: "row"
                }}
            >

                <Flex
                    direction="column"
                    marginRight="20px"
                    maxWidth={['auto', 'auto', '50%', '50%']}
                    justify={{
                        base: "center",
                        sm: "center",
                        md: "center",
                        lg: "start",
                        xl: "start"
                    }}
                >
                    <Text
                        fontSize="3xl"
                        maxWidth="100%"
                        fontWeight="bold"

                    >
                        No more typing
                    </Text>

                    <Text
                    >
                        Now you can record your task, shopping list, daily expenses by using
                        a normal conversation with Sarah. Sarah will understand what you say
                        and record down for you.
                    </Text>
                </Flex>



                <Box
                    as="iframe"
                    width={["320px", "320px", "320px", "500px"]}
                    height="315px"
                    title="Live conversation with Sarah"
                    src="https://www.youtube.com/embed/Spa0Zt1Rrt0"
                    allowFullScreen
                />



            </Flex>
        </Flex>



    );
}

export default DemoVideo;